<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.product"
                :disabled="type == 'edit'"
                dense
                :label="$t('select_product_*')"
                :items="['oem', 'fleet']"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin && type !== 'edit'"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.selectTenant"
                :disabled="type == 'edit'"
                dense
                :loading="tenantLoading"
                :items="tenantLIST"
                item-text="name"
                item-value="id"
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
                return-object
                :rules="[rules.required]"
                @change="checkTenant"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin && type == 'edit'"
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="
                  options.product == 'fleet'
                    ? options.tenant_fleet_name
                    : options.tenant_name
                "
                dense
                disabled
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="options.vehicle_id"
                dense
                :loading="isVehicleList"
                :disabled="type == 'edit'"
                :items="options.tenant_id && vehicleList"
                item-value="id"
                item-text="name"
                :label="$t('select_vehicle_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="options.maintenance_services_id"
                dense
                :loading="isLoading"
                :items="maintenanceServices"
                item-value="id"
                item-text="name"
                :label="$t('select_maintenance_service_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.service_details"
                dense
                :disabled="readOnly"
                :label="$t('service_details_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <single-date-picker
                :key="options.id"
                v-model="options.service_date"
                :label="$t('service_date_*')"
                :max="options.next_service_date"
                :disabled="readOnly"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.cost"
                dense
                :disabled="readOnly"
                :label="$t('cost')"
                :rules="[rules.float(2)]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.current_odometer_reading"
                dense
                :disabled="readOnly"
                :label="$t('current_odometer_reading_*')"
                :rules="[
                  rules.required,
                  rules.float(2),
                  rules.lessThan(options.next_mileage_reading),
                ]"
              />
            </v-col>
            <v-col
              v-if="options.recurring == 'yes'"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.next_mileage_reading"
                dense
                :disabled="readOnly"
                :label="$t('next_mileage_reading_*')"
                :rules="[
                  options.next_service_date ? true : rules.required,
                  rules.float(2),
                  rules.greaterThan(options.current_odometer_reading),
                ]"
              />
            </v-col>
            <v-col
              v-if="options.recurring == 'yes'"
              cols="12"
              sm="6"
            >
              <single-date-picker
                v-model="options.next_service_date"
                :label="$t('next_service_date_*')"
                :disabled="readOnly"
                :min="options.service_date"
                :clearable="true"
                :rules="[options.next_mileage_reading ? true : rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.workshop_name"
                dense
                :disabled="readOnly"
                :label="$t('workshop_name')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {
    SingleDatePicker: () => import("@/components/base/SingleDatePicker.vue"),
  },
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectTenant: {},
      options: {
        tenant_id: null,
      },
      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      //
      isVehicleList: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      //
      isLoading: "maintenanceLogs/getLoading",
      maintenanceServices: "maintenanceLogs/getMaintenanceServices",
    }),
    tenantLIST() {
      if (this.options.product == "oem") {
        return this.tenantsList.filter((r) => r.product == "oem");
      } else if (this.options.product == "fleet") {
        return this.tenantsList.filter((r) => r.product == "fleet");
      } else {
        return [];
      }
    },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     // this.checkTenant(val);
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
    "options.vehicle_id": {
      handler: async function (id) {
        const row = this.vehicleList.find((r) => r.id === id);
        if (this.type !== "edit") {
          this.options.current_odometer_reading = row?.odometer_reading;
        }
      },
    },
    "options.maintenance_services_id": {
      handler: async function (id) {
        const row = this.maintenanceServices.find((r) => r.id === id);
        this.options.recurring = row.recurring;
      },
    },
  },
  async mounted() {
    if (this.type == "create") {
      if (this.authUser.isAdmin) {
        this.fetchDependents(this.authUser.tenant_id);
      } else {
        this.options.tenant_id = this.authUser.tenant_id;
      }
      // this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      // const tRow = this.tenantsList.find((r) => r.id == this.item.tenant_id);
      // this.options.selectTenant = tRow;

      this.options = { ...this.item };
      this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    async fetchDependents() {
      // ("asdasd fetch dept", tId);
      this.getVehicleList();
    },
    async getVehicleList() {
      const params = {
        tenant_id: this.options.tenant_id,
        fleetId: this.options.fleetId,
        oem_b2b_ids: this.options.oem_b2b_ids,
        performance_comparison: true,
      };
      await this.$store.dispatch("vehicles/list", params);
    },

    checkTenant(val) {
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      if (val.product == "oem") {
        this.options.tenant_id = val.id;
      } else if (val.product == "fleet") {
        this.options.fleetId = val.id;
        this.options.oem_b2b_ids = val.oem_b2b_ids;
        this.options.tenant_id = val.parent_tenant_ids[0];
      }
      this.getVehicleList();
    },

    //
  },
};
</script>
<style lang="sass" scoped>
.connectorsRow

  display: flex
  gap:1rem
  .field
    min-width:150px !important
    max-width:165px !important


.deleteButton
  cursor: pointer
  .deleteIcon
    &:hover
      color: red
</style>
